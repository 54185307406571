import { Component } from '@angular/core';
import { SplashController } from '../services/splash-controller.service';

@Component({
  selector: 'app-splash',
  styleUrls: ['./splash.component.scss'],
  template: `
    <div *ngIf="splashController.showSplash" [class]="splashController.splashAnim + ' overlay'">
      <div class="image"></div>
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  `,
})
export class SplashComponent {
  constructor(public splashController: SplashController) {}
}
