import { Component } from '@angular/core';
import { PopUpController } from 'src/app/shared/components/pop-up/services/pop-up-controller.service';

@Component({
  selector: 'app-legal-policies',
  templateUrl: './legal-policies.component.html',
  styleUrls: ['./legal-policies.component.scss'],
})
export class LegalPoliciesComponent {
  /**
   *
   * @param popUpController Service to manage the pop-up
   */
  constructor(private popUpController: PopUpController) {}

  /**
   * Close the pop-up
   */
  closePopUp() {
    this.popUpController.closePopUp();
  }
}
