import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-prefix-item',
  templateUrl: './prefix-item.component.html',
  styleUrls: ['./prefix-item.component.scss'],
})
export class PrefixItemComponent implements OnInit {
  /**Item to show */
  @Input() showPrefix = true;
  /**Item to show */
  @Input() item: any;
  /**Name of item */
  name = '';
  /**Prefix of name */
  prefix = '';

  ngOnInit(): void {
    this.name = this.item.name.trim();
    this.setPrefix();
  }
  /**
   * Sets the prefix of the item depi
   */
  setPrefix() {
    if (!this.name) {
      return;
    }

    const nameArray: string[] = this.name.split(' ');
    if (nameArray[1]) {
      this.prefix = nameArray[0][0] + nameArray[1][0];
    } else {
      this.prefix = nameArray[0][0] + (nameArray[0][1] || '');
    }
  }
}
