<div [class]="'wrapper ' + toastController.animation">
  <div
    class="toast"
    [ngStyle]="{ 'border-left': '8px solid var(--app-' + toastController.toastInfo.color + '-color)' }">
    <div class="container-1">
      <app-spinner [color]="toastController.toastInfo.color" *ngIf="toastController.loading"></app-spinner>
      <em
        [ngStyle]="{ color: 'var(--app-' + toastController.toastInfo.color + '-color)' }"
        *ngIf="!toastController.loading"
        [class]="toastController.toastInfo.icon"></em>
    </div>
    <div class="container-2">
      <p>{{ toastController.toastInfo.title }}</p>
      <p>{{ toastController.toastInfo.message }}</p>
    </div>
    <button *ngIf="!toastController.loading" class="button" (click)="toastController.showToast = false">&times;</button>
  </div>
</div>
