<div
  class="progress-bar"
  [ngStyle]="{
    'background-color': 'var(--app-' + backgroundContainer + '-color)',
    width: width
  }">
  <div
    class="progress-bar-fill"
    [ngStyle]="{
      'background-color': 'var(--app-' + backgroundProgress + '-color)',
      width: progress + '%'
    }"></div>
</div>
