import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() width = '100%';
  @Input() height = '';
  @Input() background = 'primary';
  @Input() textColor = 'dark';
  @Input() borderRadius = '22px';
  @Input() borderBottom = '';
  @Input() borderColor = '';
  @Input() borderComplete = '';
  @Input() padding = '10px 20px';
  @Input() disable = false;
  @Input() buttonHover = true;
  @Input() style = {};
  @Input() size: 'medium' | 'small' = 'medium';
  @Output() click = new EventEmitter();

  onClick(event: Event) {
    if (this.disable) {
      event.stopPropagation();
    }
  }

  get ngStyle() {
    return {
      color: 'var(--app-' + this.textColor + '-color)',
      width: this.width,
      height: this.height,
      padding: this.padding,
      'background-color': this.disable ? 'var(--app-medium-gray-color)' : 'var(--app-' + this.background + '-color)',
      'border-radius': this.borderRadius,
      border: this.borderComplete + ' var(--app-' + this.borderColor + '-color)',
      'border-bottom':
        (this.borderComplete ? this.borderComplete : this.borderBottom) + ' var(--app-' + this.borderColor + '-color)',
      cursor: this.disable ? 'auto' : 'pointer',
      ...this.style,
    };
  }
}
