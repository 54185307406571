<footer class="footer" [ngClass]="{'auth-footer': authFooter}">
  <div class="links">
    <a class="pointer font-weight-bold" (click)="showLegalPolicies()">PRIVACY POLICY</a>
    <a class="pointer font-weight-bold" (click)="showTermsOfService()">TERMS & CONDITIONS</a>
  </div>
  <div class="powered-by">
    <span class="font-weight-bold">Powered by</span>
    <img src="assets/images/CE-TV-logo.png" alt="logo"
      height="42px" class="pointer" (click)="goToCETV()" />
  </div>
</footer>
