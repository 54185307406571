import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastController {
  toastInfo: ToastInfo = {
    title: '',
    message: '',
    icon: '',
    color: '',
    duration: 0,
  };

  /** variable to change the animation of the toast when it is opened or closed*/
  animation = 'toastFadeIn';

  showToast = false;
  loading = false;

  openToast(toastInfo: ToastInfo) {
    return new Promise<void>((resolve) => {
      this.animation = 'toastFadeIn';
      this.toastInfo = toastInfo;
      this.showToast = true;
      this.loading = false;
      if (toastInfo.duration) {
        setTimeout(() => {
          this.closeToast().then(() => {
            resolve();
          });
        }, toastInfo.duration);
      }
    });
  }

  /**
   *Method that close the toast
   */
  closeToast() {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        this.animation = 'toastFadeOut';
        setTimeout(() => {
          this.showToast = false;
          resolve();
        }, 500);
      }, 500);
    });
  }

  startLoading(data: { title: string; message: string; color?: string }) {
    this.loading = true;
    return new Promise<void>((resolve) => {
      this.animation = 'toastFadeIn';
      this.toastInfo.title = data.title;
      this.toastInfo.message = data.message;
      this.toastInfo.color = data.color || 'primary';
      this.showToast = true;
      resolve();
    });
  }

  stopLoading() {
    return new Promise<void>((resolve) => {
      this.closeToast().then(() => {
        this.loading = false;
        resolve();
      });
    });
  }

  handleSuccess(data: { message: string; title?: string; duration?: number; color?: string }) {
    const { message, title, duration, color } = data;
    return new Promise<void>((resolve) => {
      this.openToast({
        title: title || 'Success',
        message: message,
        duration: duration,
        color: color || 'success',
        icon: 'bx bx-check-circle',
      }).then(() => {
        resolve();
      });
    });
  }

  handleError(data: { message: string; title?: string; duration?: number }) {
    return new Promise<void>((resolve) => {
      this.openToast({
        title: data.title || 'Error',
        message: data.message,
        duration: data.duration,
        color: 'danger',
        icon: 'bx bx-error',
      }).then(() => {
        resolve();
      });
    });
  }
}

interface ToastInfo {
  title: string;
  message: string;
  color: string;
  duration?: number;
  icon: string;
}
