import { Component, Input } from '@angular/core';
import { PopUpController } from 'src/app/shared/components/pop-up/services/pop-up-controller.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent {
  /**Screen Object */
  @Input() termsAccepted = false;
  /**Show accept terms checkbox depending on page */
  @Input() showCheckbox = true;

  /**
   *
   * @param popUpController Service to manage the pop-up
   */
  constructor(private popUpController: PopUpController) {}

  /**
   * Close the pop-up
   */
  closePopUp() {
    this.popUpController.closePopUp({ termsAccepted: this.termsAccepted });
  }
}
