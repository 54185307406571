<div [ngStyle]="{ opacity: disable ? '0.5' : '1', 'pointer-events': disable || readonly ? 'none' : 'auto' }">
  <input
    [class]="containerClass"
    #checkbox
    [disabled]="disable"
    [readonly]="readonly"
    [id]="checkboxId"
    type="checkbox"
    (change)="toggleCheckbox()"
    [checked]="value" />
  <label [for]="checkboxId">
    <ng-content></ng-content>
  </label>
</div>
