import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent {
  @ViewChild('checkbox') checkbox!: ElementRef<HTMLElement>;
  @Output() valueChange = new EventEmitter();
  @Input() value = false;
  @Input() containerClass = '';
  @Input() disable = false;
  @Input() readonly = false;
  @Input() checkboxId = '';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: any) => {
    //not implemented
  };
  onTouch: any = () => {
    //not implemented
  };

  writeValue(value: any) {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Select an item an emit the value to the parent
   */
  toggleCheckbox() {
    if (this.disable) {
      return;
    }
    if (this.value) {
      this.value = false;
    } else {
      this.value = true;
    }
    this.onTouch();
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  onClick(event: Event) {
    if (this.disable) {
      event.stopPropagation();
    }
  }
}
