<app-card class="pointer" borderRadius="8px" [pin]="true">
  <div class="prefix-item">
    <div *ngIf="prefix && showPrefix" class="prefix">
      <h4>
        {{ prefix | uppercase }}
      </h4>
    </div>
    <h4>
      {{ name }}
    </h4>
    <ng-content select="[end]"></ng-content>
  </div>
</app-card>
