import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchUrl',
})
export class MatchUrlPipe implements PipeTransform {
  /**
   * checks an url against an array to know if is inside
   * @param value url to match
   * @param array array that contains urls
   * @returns boolean
   */
  transform(value: string, array: string[]): boolean {
    return array.some((valueArr) => value.includes(valueArr));
  }
}
