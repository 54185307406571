<div
  [class]="class"
  [ngStyle]="{
    'margin-top': marginTop,
    'margin-bottom': marginBottom,
    'margin-left': marginLeft,
    'margin-right': marginRight,
    width: width,
    'max-width': maxWidth,
    height: height,
    'max-height': maxHeight
  }">
  <ng-content></ng-content>
</div>
