import { Component } from '@angular/core';
import { ToastController } from '../../services/toast-controller.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  constructor(public toastController: ToastController) {}
}
