import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeZh from '@angular/common/locales/en';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SplashModule } from './shared/components/splash/splash.module';
import { TokenInterceptorService } from './shared/interceptors/token-interceptor.service';
import { CoreModule } from './core/core.module';
import { AlertModule } from './shared/components/alert/alert.module';
import { PopUpModule } from './shared/components/pop-up/pop-up.module';
import { ToastModule } from './shared/components/toast/toast.module';
registerLocaleData(localeZh);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
    SplashModule,
    CommonModule,
    CoreModule,
    AlertModule,
    PopUpModule,
    ToastModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
