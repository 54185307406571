import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopUpConfig } from '../interfaces/pop-up.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PopUpController {
  private animation = '';
  private show = false;
  private popUpConfiguration!: PopUpConfig;
  private closed = new EventEmitter();
  hidde = false;

  openPopUp(popUpConfiguration: PopUpConfig) {
    this.popUpConfiguration = popUpConfiguration;
    this.animation = 'popUpFadeIn';
    this.show = true;
  }

  closePopUp(data?: any) {
    return new Promise<void>((resolve) => {
      this.animation = 'popUpFadeOut';
      setTimeout(() => {
        this.show = false;
        this.closed.emit(data);
        resolve();
      }, 300);
    });
  }

  popUpClosed() {
    return new Promise<any>((resolve) => {
      let subscription = new Subscription();
      subscription = this.closed.subscribe((data) => {
        resolve(data);
        subscription.unsubscribe();
      });
    });
  }

  hiddePopUp() {
    return new Promise<void>((resolve) => {
      this.animation = 'popUpFadeOut';
      setTimeout(() => {
        this.hidde = false;
        resolve();
      }, 300);
    });
  }
  unHiddePopUp() {
    return new Promise<void>((resolve) => {
      this.animation = 'popUpFadeIn';
      this.hidde = false;
      resolve();
    });
  }

  get showPopUp() {
    return this.show;
  }
  get hiddenPopUp() {
    return this.hidde;
  }
  get animationPopUp() {
    return this.animation;
  }
  get popUpConfig() {
    return this.popUpConfiguration;
  }
}
