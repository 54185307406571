import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent {
  @Input() marginTop = '20px';
  @Input() marginBottom = '0';
  @Input() width = '';
  @Input() maxWidth = '';
  @Input() height = '';
  @Input() maxHeight = '';
  @Input() marginLeft = '';
  @Input() marginRight = '';
  @Input() class = '';
}
