import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerModule } from '../spinner/spinner.module';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  declarations: [ToastComponent],
  imports: [CommonModule, SpinnerModule],
  exports: [ToastComponent],
})
export class ToastModule {}
