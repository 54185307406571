import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { DynamicHostDirective } from './directives/dynamic-host.directive';

@NgModule({
  declarations: [PopUpComponent, DynamicHostDirective],
  imports: [CommonModule],
  exports: [PopUpComponent],
})
export class PopUpModule {}
