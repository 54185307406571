<div class="container">
  <div
    [hidden]="popUpController.hiddenPopUp"
    [class]="'popup ' + popUpController.animationPopUp"
    [ngStyle]="popUpController.popUpConfig.styles">
    <div
      *ngIf="popUpController.popUpConfig.component"
      dynamicHost
      [dynamicComponent]="popUpController.popUpConfig.component"></div>
    <div
      *ngIf="popUpController.popUpConfig.templateRef"
      dynamicHost
      [dynamicTemplate]="popUpController.popUpConfig.templateRef"></div>
  </div>
</div>
