import { AfterContentChecked, Component, Input } from '@angular/core';
import { PopUpController } from 'src/app/shared/components/pop-up/services/pop-up-controller.service';
import { LegalPoliciesComponent } from '../legal-policies/legal-policies.component';
import { TermsOfServiceComponent } from '../terms-of-service/terms-of-service.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterContentChecked {
  authFooter: boolean = false;

  /**
   *
   * @param popUpController service to manage pop-up
   */
  constructor(private popUpController: PopUpController) {}

  ngAfterContentChecked(): void {
    const path = window.location.pathname.split('/')[1]
    this.authFooter = path === 'login' || path === 'sign-up' ||
      path === 'forgot' || path === 'reset';
  }

  /**Shows terms of service */
  showTermsOfService() {
    this.popUpController.openPopUp({
      component: TermsOfServiceComponent,
      componentProps: {
        showCheckbox: false,
      },
      styles: {
        maxWidth: '500px',
        maxHeight: '500px',
      },
    });
  }
  /**Shows legal policies*/
  showLegalPolicies() {
    this.popUpController.openPopUp({
      component: LegalPoliciesComponent,
      styles: {
        maxWidth: '500px',
        maxHeight: '500px',
      },
    });
  }

  /**Redirects to CETV website */
  goToCETV() {
    window.open('https://www.cetvnow.com/', '_blank');
  }
}
