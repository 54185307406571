import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchUrlPipe } from './pipes/match-url.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DropdownContainerModule } from '../shared/components/dropdown-container/dropdown-container.module';
import { PrefixItemComponent } from './components/prefix-item/prefix-item.component';
import { CardModule } from '../shared/components/card/card.module';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { CheckboxModule } from '../shared/components/checkbox/checkbox.module';
import { ItemModule } from '../shared/components/item/item.module';
import { FormsModule } from '@angular/forms';
import { LegalPoliciesComponent } from './components/legal-policies/legal-policies.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    MatchUrlPipe,
    PrefixItemComponent,
    TermsOfServiceComponent,
    LegalPoliciesComponent,
  ],
  imports: [CommonModule, DropdownContainerModule, CardModule, CheckboxModule, ItemModule, FormsModule, RouterModule],
  exports: [FooterComponent, NavbarComponent, PrefixItemComponent],
})
export class CoreModule {}
