import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AccountService } from 'src/app/account/services/account.service';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.BASE_URL;
const PLATFORM = environment.PLATFORM;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   *
   * @param http http client to send http requests
   * @param router Object to manage the routing
   */
  constructor(private http: HttpClient, private router: Router, private accountService: AccountService) {}

  /**
   * method that sends the request to the login API
   * @param email_address string that contains the user email
   * @param password string that contains the user password
   */
  login(email_address: string, password: string) {
    const body = {
      platform: PLATFORM,
      login_data: { email_address, password }
    }

    return this.http.post(`${BASE_URL}auth/login`, body).pipe(
      tap((resp: any) => {
        this.handleAuth(resp);
      })
    );
  }

  /**
   * method that clears the token, user_id and the account_id to close the session and go to the login.
   */
  logout() {
    localStorage.clear();
    this.accountService.account = null;
    this.router.navigateByUrl('/');
  }

  /**
   * method that sends the request to register a new user
   * @param userData object that contains the userData to join telemated
   */
  register(userData: any) {
    return this.http.post(`${BASE_URL}register`, userData).pipe(
      tap((resp: any) => {
        this.handleAuth(resp);
      })
    );
  }

  /**
   * method that saves the token, user_id and account_id to make requests
   * @param resp object that contains the response data to be saved
   */
  handleAuth(resp: any) {
    localStorage.setItem('token', resp.token);
    localStorage.setItem('account_id', resp.account_id);
    localStorage.setItem('user_id', resp.id);
    localStorage.setItem('user_role', resp.role);
  }
  /**
   * method that checks if the user exists
   * @param email_address string that contains the email of a user to be check if exists in the system
   */
  checkExistingEmail(email_address: string) {
    return this.http.get(`${BASE_URL}register/check/email?email_address=${email_address}`);
  }

  /**
   * method that call the API and send an email to reset the password
   * @param email_address string that contains the email of a user to send the reset email
   */
  forgotPassword(email_address: string) {
    const url = window.location.href.split('/')[2];
    const body = {
      platform_url: url,
      email_address: email_address,
    }
    return this.http.post(`${BASE_URL}auth/forgot-password`, body);
  }

  /**
   * method that call the API and send an email to reset the password
   * @param fp_token strin that contains the token of a user to recover the account password
   * @param password new password of an account
   */
  resetPassword(fp_token: string, password: string) {
    return this.http.post(`${BASE_URL}auth/reset-password`, { fp_token, password });
  }

  /**
   * get the token
   */
  get token() {
    return localStorage.getItem('token') || '';
  }

  /**
   * getter to use globally the account_id in telemated
   */
  get account_id() {
    return localStorage.getItem('account_id') || '';
  }
  /**
   * getter to use user_id the account_id in telemated
   */
  get user_id() {
    return localStorage.getItem('user_id') || '';
  }
}
