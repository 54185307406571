import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DynamicHostDirective } from '../../directives/dynamic-host.directive';
import { PopUpController } from '../../services/pop-up-controller.service';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent implements AfterViewInit {
  @ViewChild(DynamicHostDirective) hostDirective!: DynamicHostDirective;

  constructor(public popUpController: PopUpController, private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.popUpController.popUpConfig.component) {
      this.createComponent();
    } else if (this.popUpController.popUpConfig.templateRef) {
      this.createTemplateRef();
    }
  }

  createComponent() {
    this.hostDirective.createComponet();
    if (this.popUpController.popUpConfig.componentProps) {
      Object.keys(this.popUpController.popUpConfig.componentProps).forEach((key) => {
        this.hostDirective.componentRef.instance[key] = this.popUpController.popUpConfig.componentProps[key];
      });
    }
    this.changeDetector.detectChanges();
  }

  createTemplateRef() {
    this.hostDirective.createView();
    this.changeDetector.detectChanges();
  }
}
