import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { AlertController } from './shared/components/alert/services/alert-controller.service';
import { PopUpController } from './shared/components/pop-up/services/pop-up-controller.service';
import { SplashController } from './shared/components/splash/services/splash-controller.service';
import { ToastController } from './shared/components/toast/services/toast-controller.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked {
  hideNavbar: boolean = false;

  constructor(
    public alertController: AlertController,
    public popUpController: PopUpController,
    public toastController: ToastController,
    private splashController: SplashController,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.splashController.initialSplash();
  }

  ngAfterContentChecked(): void {
    const path = window.location.pathname.split('/')[1]
    this.hideNavbar = path === 'login' || path === 'reset' ||
      path === 'sign-up' || path === 'forgot';
    this.changeDetectorRef.detectChanges();
  }
}
