<div [class]="'card ' + class"
  [ngStyle]="{
    background: 'var(--app-' + background + '-color)',
    cursor: cursor,
    'border-radius': borderRadius,
    'border-style': borderStyle,
    'border-width': borderWidth,
    'border-color': 'var(--app-' + borderColor + '-color)'
  }">
  <div class="pin-container" *ngIf="pin">
    <div class="orange-pin"></div>
  </div>

  <section class="card-container"
  [ngStyle]="{
    color: 'var(--app-' + textColor + '-color)',
    padding: padding
  }">
    <ng-content></ng-content>
  </section>
</div>
