import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() backgroundContainer = 'soft-gray';
  @Input() backgroundProgress = 'dark';
  @Input() width = '100%';
  @Input() progress = 0;
}
