import { ComponentRef, Directive, EmbeddedViewRef, Input, TemplateRef, Type, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dynamicHost]',
})
export class DynamicHostDirective {
  @Input() dynamicComponent!: Type<any>;
  componentRef!: ComponentRef<any>;
  @Input() dynamicTemplate!: TemplateRef<any>;
  templateRef!: EmbeddedViewRef<any>;

  constructor(public viewContainerRef: ViewContainerRef) {}

  createComponet(): void {
    this.componentRef = this.viewContainerRef.createComponent(this.dynamicComponent);
  }

  createView() {
    this.templateRef = this.viewContainerRef.createEmbeddedView(this.dynamicTemplate);
  }
}
