<div class="alert-container">
  <div [class]="'alert ' + alertController.animationAlert">
    <div class="alert-content">
      <div
        class="icon"
        *ngIf="alertController.alertInfo.showIcon"
        [style.border-color]="'var(--app-' + alertController.alertInfo.iconColor + '-color)'">
        <em
          [class]="alertController.alertInfo.iconName"
          [style.color]="'var(--app-' + alertController.alertInfo.iconColor + '-color)'"></em>
      </div>
      <div class="title">{{ alertController.alertInfo.title }}</div>
      <div class="description" [innerHTML]="alertController.alertInfo.msg"></div>

      <div class="footer">
        <app-item class="flex flex-center gap-10">
          <app-button
            [textColor]="button.buttonTextColor || 'light'"
            [size]="button.buttonSize || 'medium'"
            *ngFor="let button of alertController.alertInfo.buttons"
            (click)="button.buttonHandler()"
            [background]="button.buttonColor">
            <em *ngIf="button.buttonIcon" [class]="button.buttonIcon"></em>
            {{ button.buttonText }}
          </app-button>
        </app-item>
      </div>
    </div>
  </div>
</div>
