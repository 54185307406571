import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownContainerComponent } from './dropdown-container.component';

@NgModule({
  declarations: [DropdownContainerComponent],
  imports: [CommonModule],
  exports: [DropdownContainerComponent],
})
export class DropdownContainerModule {}
