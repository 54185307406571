import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  account: any;
  /**
   *
   * @param http http client to send http requests
   */
  constructor(private http: HttpClient) {}

  getAccount() {
    return this.http.get(`${BASE_URL}accounts/${localStorage.getItem('account_id')}`);
  }

  /**
   * getter to use globally the account_id in telemated
   */
  get account_id() {
    return localStorage.getItem('account_id') || '';
  }
}
