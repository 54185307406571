import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SplashController {
  /**Variables to manage the splash */
  showSplash = false;
  splashAnim = '';

  initialSplash() {
    this.showSplash = true;
    setTimeout(() => {
      this.splashAnim = 'animated fadeOut slow';
      setTimeout(() => {
        this.showSplash = false;
      }, 1000);
    }, 1000);
  }

  showSplashScreen() {
    this.splashAnim = 'animated fadeIn';
    this.showSplash = true;
  }

  hiddeSplashScreen() {
    setTimeout(() => {
      this.splashAnim = 'animated fadeOut slow';
      setTimeout(() => {
        this.showSplash = false;
      }, 1000);
    }, 500);
  }
}
